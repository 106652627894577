.footer-info {
  padding: 50px 0;
  color: $gray-4;
  background-color: $gray-3;

  @media screen and (max-width: 640px) {
    .container {
      padding: 0;
    }
  }

  &-title {
    margin: 0 0 35px;
    font-weight: 300;
    line-height: 1;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 640px) {
      text-align: center;
    }
  }

  &-content {
    margin-bottom: 25px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;

    @media screen and (max-width: 640px) {
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $gray-4;

      &:hover,
      &:focus {
        color: $gray-4;
      }
    }

    i {
      margin-right: 5px;

      @media screen and (max-width: 640px) {
        display: block;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 1;
      }
    }

    p {
      display: inline-block;
      margin: 0;

      @media screen and (max-width: 640px) {
        word-break: break-word;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    &-opening-hours {
      margin: 40px 0;
    }
  }

  &-map-canvas {
    width: 100%;
    height: 300px;
  }
}
