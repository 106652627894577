@import "common/variables";

@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap";
@import "../../bower_components/coponents-font-awesome/css/font-awesome.css";

@import "common/global";
@import "components/header";
@import "components/_carousel";
@import "components/_info";
@import "components/_our-mission";
@import "components/_services-home";
@import "components/_novoa";
@import "components/_testimonial";
@import "components/_footer-info";
@import "components/_footer";
@import "components/_page-content";
@import "components/_article";
@import "components/_blog-latest";
@import "components/_services";
@import "components/_nav";
@import "components/_page-single";
@import "components/_comments";
