.footer {
  padding: 20px 0;
  background-color: $white;

  &-content {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    text-align: center;
    color: $gray-2;

    & + & {
      margin-top: 5px;
    }
  }
}
