.novoa {
  float: left;
  width: 100%;
  color: $white;
  background-color: $blue;

  @media screen and (max-width: 767px) {
    padding: 35px 0;
  }

  &-image {
    margin: 0 auto;
    max-height: 650px;

    @media screen and (max-width: 767px) {
      max-height: 500px;
    }
  }

  &-title {
    padding-top: 150px;
    margin: 0 0 25px;
    font-weight: 300;

    @media screen and (max-width: 991px) {
      padding-top: 50px;
    }

    @media screen and (max-width: 767px) {
      padding-top: 35px;
    }

    @media screen and (max-width: 640px) {
      margin-bottom: 15px;
      font-size: 24px;
    }
  }

  &-content {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 1.65;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &-button {
    display: inline-block;
    padding: 20px 32px;
    margin-top: 35px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 1;
    color: $white;
    background-color: $yellow;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &-page {
    padding: 120px 0;
    background: $white url('../images/clinica_cinthia.jpg') no-repeat;
    background-size: cover;

    @media screen and (max-width: 991px) {
      padding: 35px 0;
      background-image: none;
    }

    &-title {
      padding: 0;
      color: $yellow-2;

      @media screen and (max-width: 991px) {
        margin-top: 35px;
      }
    }

    &-content {
      font-size: 16px;
      color: $gray-4;

      & + & {
        margin-bottom: 30px;
      }
    }
  }
}
