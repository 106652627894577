.nav > li {
  .icon-caret {
    position: absolute;
    z-index: 1;
    padding: 0;
    right: 0;
    padding: .5em;
  }
}

.sub-menu {
  display: none;
}

// Small screen (max-width)
@media (max-width: $screen-sm-max) {
  .navbar-nav .open .dropdown-menu > li > a {
    padding-left: 2em;
  }

  .navbar-nav .open .dropdown-menu {
    padding-top: 0;
    padding-bottom: .5em;
    margin-bottom: .5em;
    border-bottom: solid 1px #dedede;
  }

  .nav > li {
    .icon-caret {
      font-size: 1.2em;
      top: 3px;
      padding: .5em 1em;
    }
  }
}

// Medium screen (min-width)
@media (min-width: $screen-md-min) {
  .nav > li {
    .icon-caret {
      top: 30%;
    }
  }

  .navbar-right .dropdown-menu {
    right: -1.5em;
  }
}
