.page-single {
  float: left;
  padding: 80px 0;
  width: 100%;
  background-color: $gray-5;

  &-blog {
    padding: 80px 0;
  }

  .container {
    padding: 0;
  }

  &-shadow {
    width: 100%;
    height: 120px;
    background: linear-gradient(to bottom, transparent 0%, rgba($black, .05) 100%);
  }
}
