.services-home {
  padding: 110px 0 70px;
  text-align: center;

  @media screen and (max-width: 767px) {
    padding: 50px 0 0;
  }

  .container {
    padding: 0;
  }

  &-title {
    margin: 0 0 30px;
    min-height: 80px;
    font-size: 30px;
    color: $yellow-2;
    vertical-align: top;

    @media screen and (max-width: 1199px) {
      margin: 0 0 15px;
      min-height: 0;
    }

    @media screen and (max-width: 640px) {
      font-size: 24px;
    }
  }

  &-content {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 1.65;
    color: $gray;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      display: inline-block;
      padding: 0 25px;
      margin: 0 0 50px;
      width: calc(25% - 15px);

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @media screen and (min-width: 992px) and (max-width: 1199px) {
        width: calc(50% - 15px);
      }

      @media screen and (max-width: 991px) {
        padding: 0;
        width: 100%;
      }
    }
  }
}
