.article {
  &-wrapper {
    padding-top: 40px;
    background-color: $gray-5;
  }

  &-shadow {
    width: 100%;
    height: 120px;
    background: linear-gradient(to bottom, transparent 0%, rgba($black, .05) 100%);
  }
}

.post {
  display: block;
  float: left;
  width: 100%;
  background-color: $white;

  &-image {
    width: 100%;
    height: auto;
  }

  &-header {
    padding: 0 60px 0 60px;

    @media screen and (max-width: 767px) {
      padding: 0 15px 0 15px;
    }
  }

  &-title {
    margin: 60px 0 10px;
    font-weight: 300;
    color: $yellow-2;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
      font-size: 32px;
    }
  }

  &-date {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: $gray-2;
  }

  &-content {
    padding: 0 60px 60px;
    margin-top: 30px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: $gray-4;

    img {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      padding: 0 15px 15px;
    }
  }

  &-nav {
    display: inline-block;
    margin: 35px 0;
    width: 100%;

    &-left a,
    &-right a {
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      color: $gray-6;
    }

    &-left {
      float: left;
    }

    &-right {
      float: right;
    }
  }
}
