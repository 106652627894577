.info {
  display: flex;
  flex-wrap: wrap;
}

.info-contact,
.info-opening-hours {
  flex: 1;
  padding: 50px 0;
  width: 50%;
  color: $white;

  @media screen and (max-width: 767px) {
    padding: 35px 0;
  }

  &-title {
    margin: 0 0 50px;
    font-weight: 300;
    letter-spacing: .5px;
    text-align: center;

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  &-list {
    padding: 0 35px;
    margin: 0;
    list-style: none;

    &-item {
      padding: 15px 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      border-bottom: 1px solid rgba($white, 0.15);

      &:first-child {
        border-top: 1px solid rgba($white, 0.15);
      }

      a {
        color: $white;

        &:hover,
        &:focus {
          color: $white;
        }
      }

      i {
        margin-right: 5px;

        @media screen and (max-width: 640px) {
          display: block;
          margin-bottom: 10px;
          font-size: 20px;
          line-height: 1;
        }
      }

      span {
        float: right;
      }

      p {
        display: inline-block;
        margin: 0;
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;

    &-list {
      padding: 0;
    }
  }
}

.info-contact {
  text-align: center;
  background-color: $blue;
}

.info-opening-hours {
  background-color: $blue-2;
}
