// Colors
$white: #FFFFFF;
$black: #000000;

$gray: #666666;
$gray-2: #A6ACAE;
$gray-3: #EEEEEE;
$gray-4: #3C3A3A;
$gray-5: #EDEDED;
$gray-6: #807D7C;

$blue: #4E879A;;
$blue-2: #63AFCA;
$blue-3: #686C6D;

$yellow: #C0892E;
$yellow-2: #B18733;
