.testimonial {
  padding: 110px 0;

  @media screen and (max-width: 767px) {
    padding: 70px 0;
  }

  @media screen and (max-width: 640px) {
    .container {
      padding: 0;
    }
  }

  &-quote {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: 'Raleay', sans-serif;
    font-weight: 300;
    font-size: 30px;
    font-style: italic;
    text-align: center;
    line-height: 1.3;
    color: $blue-3;
    border: none;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 50px;
      height: 50px;
      background: url('../images/aspa.svg') no-repeat;
      background-size: 50px 50px;

      @media screen and (max-width: 640px) {
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
        opacity: .5;
        z-index: -10;
      }
    }

    &:after {
      left: -80px;

      @media screen and (max-width: 640px) {
        top: -40px;
        left: 10px;
      }
    }

    &:before {
      right: -80px;
      transform: rotate(180deg);

      @media screen and (max-width: 640px) {
        top: auto;
        bottom: 0;
        right: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 22px;
    }

    @media screen and (max-width: 640px) {
      font-size: 18px;
    }

    &-author {
      margin-top: 25px;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      line-height: 1;
      color: $gray-2;

      &:before {
        display: none;
        content: '';
      }

      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
