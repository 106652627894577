.page-content {
  padding: 120px 0;
  text-align: justify;

  @media screen and (max-width: 767px) {
    padding: 50px 0;
  }

  &-background {
    float: left;
    width: 100%;
    height: 580px;
    background: url('../images/clinica_banner.jpg') no-repeat;
    background-position: center center;
    background-size: cover;

    @media screen and (max-width: 767px) {
      height: 290px;
      background: url('../images/clinica_banner_mobile.jpg') no-repeat;
      background-position: center right;
      background-size: cover;
    }
  }

  &-header {
    padding: 90px 0;
    background-color: $blue-2;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: 767px) {
      background-position: 50% 50%;
    }

    &-title {
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-size: 48px;
      line-height: 1;
      text-align: center;
      text-transform: lowercase;
      color: $white;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);

      @media screen and (max-width: 767px) {
        margin: 0 15px;
        font-size: 32px;
      }
    }
  }

  &-title {
    margin: 0 0 80px;
    font-size: 48px;
    font-weight: 300;
    letter-spacing: -1px;
    text-align: center;
    color: $yellow-2;

    @media screen and (max-width: 767px) {
      margin: 0 0 35px;
      font-size: 36px;
    }
  }

  &-text p {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.65;
    color: $gray-4;

    & + & {
      margin-bottom: 15px;
    }
  }

  &-blockquote {
    position: relative;
    margin: 80px auto 0 auto;
  }
}
