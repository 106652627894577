.services {
  float: left;
  padding: 80px 0;
  width: 100%;
  background-color: $gray-5;

  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }

  .row {
    margin: 0;
  }

  .container {
    padding: 0;
  }

  &-shadow {
    width: 100%;
    height: 120px;
    background: linear-gradient(to bottom, transparent 0%, rgba($black, .05) 100%);
  }

  &-side {
    &-title {
      margin: 0 0 25px;
      font-family: 'Raleway', sans-serif;
      font-size: 24px;
      color: $gray-4;
      line-height: 1;
    }
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &-item {
      padding-left: 20px;

      &:before {
        content: '';
        display: block;
        position: relative;
        left: -20px;
        top: 17px;
        height: 0;
        width: 0;
        border-color: transparent $blue;
        border-style: solid;
        border-width: 0.35em 0 0.35em 0.45em;
      }

      & + & {
        margin-top: 10px;
      }

      &-anchor {
        font-family: 'Raleway', sans-serif;
        font-size: 18px;
        color: $gray-6;

        b {
          font-weight: 600;
        }

        &:hover,
        &:focus {
          color: $gray-6;
          text-decoration: underline;
        }
      }
    }
  }

  &-content {
    padding: 60px;
    color: $gray-4;
    background-color: $white;

    @media screen and (max-width: 767px) {
      padding: 30px;
      margin-top: 50px;
    }

    h1 {
      margin: 0 0 35px;
      font-size: 36px;
      color: $yellow;
    }

    h3 {
      margin: 25px 0 10px;
      font-size: 24px;
    }

    p {
      font-size: 16px;
      line-height: 1.5;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
