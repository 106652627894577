.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border: none;
  box-shadow: 0 2px 3px rgba($black, 0.1);
  z-index: 999;

  &-nav {
    margin: 0;
    background-color: $white;
    border: none;
    border-radius: 0;
  }

  .container > &-navbar {
    margin: 0;
    width: 100%;
  }

  &-nav-button {
    padding: 10px;
    margin: 23px 0 23px 15px;
    border: none;

    @media screen and (max-width: 640px) {
      margin: 10px 0;

      &:hover,
      &:focus {
        background-color: $white;
      }
    }
  }

  &-logo {
    height: auto;

    @media screen and (max-width: 991px) {
      padding: 17px 15px;
    }

    @media screen and (max-width: 767px) {
      padding: 17px 0;
    }

    @media screen and (max-width: 640px) {
      padding: 15px 0 0;
    }

    &-image {
      height: 50px;

      @media screen and (max-width: 991px) {
        height: 45px;
      }

      @media screen and (max-width: 640px) {
        height: 35px;
      }
    }
  }

  &-menu {
    display: inline-block;
    margin-right: 25px;

    li > a {
      padding: 30px 15px;
      font-size: 18px;
      text-transform: lowercase;
      line-height: 1;
      color: #807D7C  !important;

      &:hover,
      &:focus {
        color: $yellow !important;
        background: none !important;
      }

      @media screen and (max-width: 991px) {
        padding: 32px 11px;
        font-size: 16px;
      }
    }

    li.current-menu-item,
    li.active {
      & > a {
        color: $yellow !important;
        background: none !important;
      }
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &-social {
    float: right;
    margin: 20px 0;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 40px 40px;

    & + & {
      margin-right: 10px;
    }

    @media screen and (max-width: 991px) {
      margin: 22px 0;
      width: 35px;
      height: 35px;
      background-size: 35px 35px;
    }

    @media screen and (max-width: 640px) {
      float: none;
      display: inline-block;
      margin: 10px;
      width: 30px;
      height: 30px;
      background-size: 30px 30px;
    }

    &-wrapper {
      display: inline-block;

      @media screen and (max-width: 640px) {
        display: none;
      }

      &-mobile {
        display: none;

        @media screen and (max-width: 640px) {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
      }
    }

    &-instagram {
      background-image: url('../images/header_bt_instagram@2x.png');

      &:hover,
      &:focus {
        background-image: url('../images/header_bt_instagram_hover@2x.png');
        text-decoration: none;
      }
    }

    &-facebook {
      background-image: url('../images/header_bt_facebook@2x.png');

      &:hover,
      &:focus {
        background-image: url('../images/header_bt_facebook_hover@2x.png');
        text-decoration: none;
      }
    }
  }

  .navbar-collapse {
    &.collapsing,
    &.in {
      position: fixed;
      top: 80px;
      right: 0;
      left: 0;
      background-color: $white;

      @media screen and (max-width: 640px) {
        top: 110px;
      }
    }

    li > a {
      font-family: 'Open Sans', sans-serif;
    }

    li.current-menu-item > a,
    li.active > a {
      color: $yellow;
      background: none;
    }
  }

  &-mobile-nav#nav {
    border-bottom: 1px solid #e7e7e7;

    @media screen and (min-width: 768px) {
      display: none !important;
    }

    .navbar-nav .open .dropdown-menu {
      border-bottom: none;
    }
  }

  .dropdown-menu {
    top: calc(100% + 5px);
    min-width: 250px;
    border: none;
    border-radius: 0;
    box-shadow: 0 2px 3px rgba($black, 0.1);

    li {
      padding: 0 30px;

      @media screen and (max-width: 767px) {
        padding: 0;
      }

      & > a {
        padding: 15px 0;
        font-size: 16px;
        border-bottom: 1px solid rgba($black, 0.1);

        @media screen and (max-width: 767px) {
          padding: 10px 20px !important;
          font-size: 14px;
          border-bottom: none;
        }
      }

      &:last-child > a {
        border-bottom: 0;
      }
    }
  }

  &.headroom {
    will-change: transform;
    transition: transform 200ms linear;
  }

  &.headroom--pinned {
    transform: translateY(0%);
  }

  &.headroom--unpinned {
    @media screen and (max-width: 767px) {
      transform: translateY(-1000%);
    }
  }

  li a.has-dropdown {
    .caret {
      margin-left: 6px;
      font-size: 16px;
      color: #807D7C;

      &:hover,
      &:focus {
        background: none;
      }
    }
  }

  .visible-xxs-block {
    display: none;

    @media screen and (max-width: 640px) {
      display: block !important;
    }
  }
}
