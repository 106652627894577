.carousel {
  &-inner > .item > img {
    margin: 0 auto;
  }

  &-caption {
    top: 50%;
    bottom: initial;
    font-size: 44px;
    color: $white;
    text-shadow: 0 1px 2px rgba($black, .3);
    transform: translateY(-50%);

    p {
      margin: 0;
    }

    @media screen and (max-width: 991px) {
      font-size: 32px;
    }

    @media screen and (max-width: 767px) {
      left: 5%;
      right: 5%;
      font-size: 18px;
    }
  }


    @media (-webkit-transform-3d), not all {
      &-inner > .item {
        transform-style: preserve-3d;
      }
    }
}
