.blog-latest {
  float: left;
  padding: 100px 0;
  width: 100%;
  color: $white;
  text-align: center;
  background-color: $gray-5;

  @media screen and (max-width: 767px) {
    padding: 35px 0;
  }

  &-title {
    margin: 10px 0 70px;
    font-size: 48px;
    font-weight: 300;
    color: $gray-4;

    @media screen and (max-width: 767px) {
      margin-bottom: 35px;
      font-size: 28px;
    }
  }

  &-subtitle {
    margin: 0;
    font-size: 24px;
    color: $gray-2;
  }

  &-columns {
    display: block;
    text-align: center;
  }

  &-post {
    display: inline-block;
    float: left;
    margin: 10px;
    width: calc(33.333% - 20px);
    background-color: $white;

    @media screen and (min-width: 992px) {
      &:nth-child(4n) {
        clear: both;
      }
    }

    @media screen and (max-width: 991px) {
      width: calc(50% - 20px);

      &:nth-child(3n) {
        clear: both;
      }
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &:nth-child(4n) {
        clear: none;
      }
    }

    &-anchor {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &-image {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }

    &-wrapper {
      padding: 50px;

      @media screen and (max-width: 767px) {
        padding: 25px;
      }
    }

    &-title {
      position: relative;
      margin: 0 0 16px;
      height: 60px;
      font-size: 24px;
      line-height: 1.25;
      color: $yellow;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        font-size: 23px;
      }
    }

    &-meta {
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      color: $gray-2;
    }

    &-content {
      position: relative;
      margin: 35px 0 0;
      height: 105px;
      font-family: 'Open Sans', sans-serif;
      color: $gray;
      line-height: 1.5;
      overflow: hidden;
      pointer-events: none;

      a {
        color: $gray;
      }

      @media screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }
}

.page-single-blog .container {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
