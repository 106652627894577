.our-mission {
  float: left;
  display: block;
  padding: 115px 0;
  width: 100%;
  color: $white;
  background-image: url('../images/home_clinica.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-shadow: 0 1px 2px rgba($black, .3);

  @media screen and (max-width: 767px) {
    padding: 70px 0 20px;
    background:-image url('../images/home_clinica_mobile.jpg');
    background-position: center right;
  }

  .container {
    padding: 0;
  }

  &-title {
    margin: 0 0 25px;
    font-weight: 300;
    line-height: 1.15;
  }

  &-content,
  &-content p {
    margin: 0 0 35px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 1.65;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  &-button {
    display: inline-block;
    padding: 20px 32px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    line-height: 1;
    color: $white;
    background-color: $yellow;

    &:hover,
    &:focus {
      color: $white;
      text-decoration: none;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}
